import { For, createEffect, createSignal, splitProps, untrack } from "solid-js";
import {
  SubmissionsListProps,
  ShowSubmissionsTableViewProps,
  SubmissionsApplicationsProps,
  SubmissionsListDetails,
} from "../../../models";
import { SubmissionRow } from "./submission-row";
import { SubmissionFilter } from "./submission-filter";
import { ROUTES } from "../../../const";
import { useParams } from "@solidjs/router";
import { state } from ":mods";
import { getSubmissionsList } from "../../../apis";

export function ShowSubmissionsTable(props: ShowSubmissionsTableViewProps) {
  const [local, others] = splitProps(props, ["$"]);
  const max_rows = 10;
  const params = useParams();
  const $details = state.createAsync([getSubmissionsList, null, null], {
    manualFetch: true,
  });
  const $submissionList = state.create([] as SubmissionsApplicationsProps[]);

  const [currentPage, setCurrentPage] = createSignal(1);
  const [submissionListDB, setSubmissionListDB] = createSignal({} as SubmissionsListProps);
  const [regionsList, setRegionsList] = createSignal([]);
  const [commissionsList, setCommissionsList] = createSignal([]);
  const [pagesNumbers, setPagesNumbers] = createSignal([]);

  $details.on((event, { value }) => {
    setSubmissionListDB(value?.data);
    setRegionsList(value?.data.regions);
    setCommissionsList(value?.data.commissions);
    const app_list = [...value?.data?.applications];
    const pagination_start = max_rows * currentPage() - max_rows;
    const pagination_end = max_rows * currentPage();
    app_list.slice(pagination_start, pagination_end);
    $submissionList.set(app_list);

    const list_length = app_list.length <= 0 ? 0 : app_list.length / 10;
    const list_length_ceiled = Math.ceil(list_length);
    setPagesNumbers(new Array(list_length_ceiled).fill(1).map((n, i) => i + 1));
    local.$.actions.updateLayout({
      title: submissionListDB().title,
      sideInfo: [],
    });
  });

  createEffect(() => {
    if ($details.state.success) {
      const value = $details.unwrap.value;
    }
  });

  createEffect(() => {
    const stage_id = params.id;
    const status_id = params.statusID;
    $details.recall(stage_id, status_id);
  });

  const onChangePage = (page) => {
    setCurrentPage(page);
    const app_list = $details.unwrap.value?.data?.applications;
    const pagination_start = max_rows * currentPage() - max_rows;
    const pagination_end = max_rows * currentPage();
    app_list.slice(pagination_start, pagination_end);
    $submissionList.set(app_list);
  };
  const onPrevPage = (e: Event) => {
    e.preventDefault();
    const prevPage = currentPage() - 1;
    if (0 < prevPage) {
      setCurrentPage(prevPage);
    } else {
      setCurrentPage(pagesNumbers().slice(-1)[0]);
    }
  };
  const onNextPage = (e: Event) => {
    e.preventDefault();
    const nextPage = currentPage() + 1;
    if (pagesNumbers().length >= nextPage) {
      setCurrentPage(nextPage);
    } else {
      setCurrentPage(1);
    }
  };
  const onOpenSubmissionPage = (stage_id, statusId, applicationID) => {
    local.$.actions.navigate(ROUTES.side.courses.foundation_submission_student(stage_id, statusId, applicationID));
  };

  const onFilterSubmissionList = (filters) => {
    const isEmpty = Object.values(filters).every((x) => !x);
    if (isEmpty) {
      // clear all
      const app_list = $details.unwrap.value?.data?.applications;
      $submissionList.set(app_list);
      return;
    }
    let copyList = [...submissionListDB().applications];
    const filtersBody = { ...filters };

    Object.keys(filters).forEach((key) => {
      if (filtersBody[key] != undefined) {
        if (typeof filtersBody[key] === "string") {
          if (key == "region") {
            const regionObj = regionsList().find(
              (r) => r.name.toLowerCase().trim() == filters[key]?.toLowerCase().trim()
            );

            filtersBody[key] = regionObj.id;
            copyList = copyList.filter((submission) => submission[key] === filtersBody[key]);
            console.log(copyList, " :region: ", filtersBody[key]);
            $submissionList.set(copyList);
          } else if (key === "which_commission") {
            const commissionObj = commissionsList().find(
              (r) => r.name.toLowerCase().trim() == filters[key]?.toLowerCase().trim()
            );

            filtersBody[key] = commissionObj.id;
            $submissionList.set(copyList.filter((submission) => submission[key] === filtersBody[key]));
          } else {
            copyList = copyList.filter(
              (submission) => submission[key]?.toLowerCase().trim() === filtersBody[key]?.toLowerCase().trim()
            );
            $submissionList.set(copyList);
          }
        }
        if (typeof filtersBody[key] === "boolean") {
          const newList = copyList.filter((submission) => submission[key] == filtersBody[key]);
          $submissionList.set(newList);
        }
      }
    });
  };

  return (
    <div class="w-full ">
      <p class="w-557px text-16px leading-22.4px my-20px">{$details.value?.data?.description ?? ""}</p>
      <section class="flex flex-col gap-40px w-99% my-20px text-16px">
        <SubmissionFilter
          submissions_count={submissionListDB()?.applications?.length}
          englishLevel={$details.value?.data.levels}
          regions={regionsList()}
          comissions={commissionsList()}
          onFilterSelected={onFilterSubmissionList}
        />
        <section id="table" class="flex flex-col gap-20px w-full">
          <For each={$submissionList.value}>
            {(row, index) => {
              const date = new Date(row?.created_at)
                .toLocaleString("default", { month: "long", day: "numeric" })
                .toString();
              const name = `${row.first_name} ${row.last_name}`;
              const title = row.exhibition_title;
              const region = [...$details.unwrap.value?.data.regions]?.find((r) => r.id === row.region);
              return (
                <SubmissionRow
                  name={name}
                  title={title}
                  region={region?.name}
                  date={date}
                  onClick={() => onOpenSubmissionPage(params.id, params.statusID, row?.application_id)}
                  class={`
                  w-full
                  ${index() >= $submissionList.value.length / 2 ? "bg-white" : ""}
                  `}
                />
              );
            }}
          </For>
          <div class="flex gap-20px justify-center items-center">
            <span class="cursor-pointer select-none" onClick={onPrevPage}>
              {"<"}
            </span>
            {pagesNumbers()?.map((page_no) => {
              return (
                <p
                  class={`cursor-pointer ${currentPage() == page_no && "font-700"}`}
                  onClick={() => onChangePage(page_no)}
                >
                  {page_no}
                </p>
              );
            })}
            <span class="cursor-pointer select-none" onClick={onNextPage}>
              {">"}
            </span>
          </div>
        </section>
      </section>
    </div>
  );
}
